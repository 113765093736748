import React  , { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import Firebase from 'firebase';
import firebaseConfig from "./config/firebaseConfig";

Firebase.initializeApp(firebaseConfig);

function App() {
  const [show, setShow] = useState(false);
  const [Awb,timeStamp,City,Destination,Status, TransactionNumber] = '';
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const trackShippment = () =>{
    let Awb = document.getElementById('awb').value;
    let result = document.getElementById('trackResult');

    $.get("https://script.google.com/macros/s/AKfycbw4JZa-qbwp5e2aNsA3nBeds5u2U6DgH-7TPaRaHRb0Noc50VNfnwT9AfNFqFjTtW21Yg/exec?ssId=1ZSIrOSgzCiJbfK3ndgVxUUv2GbN_uRCkLKOSU5Xy__U&action=query&awb="+Awb+"&task=getAwbDetails", function(data, status){
      var details = JSON.parse(data);
      if(details.code === false){
        result.innerHTML = "";
        result.innerHTML = "<p>Entered Tracking Number is Invalid, Please enter valid Tracking number</P>"
      }else{
        var pType = details[0].customer === "CASH" ? "PREPAID" : "COD";
        result.innerHTML = "";
        result.innerHTML='<table class="table table-bordered">'+
        '<tr><th>TimeStamp</th><td>'+details[0].timestamp+'</td></tr>'+
        '<tr><th>Origin</th><td>Airoli, Navi Mumbai (MH)</td></tr>'+
        '<tr><th>Destination</th><td>'+details[0].destination+'</td></tr>'+
        '<tr><th>Weight</th><td>'+details[0].weight+' (Kg.)</td></tr>'+
        '<tr><th>Type</th><td>'+details[0].mode+'</td></tr>'+       
        '<tr><th>Payment Type</th><td>'+ pType +'</td></tr>'+
        '<tr><th>Status</th><td>'+details[0].type+'</td></tr></table>';
      }
    });
  }

  return (
    <div id="head">
    <header class="masthead text-center text-white d-flex">
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h1 class="text-uppercase">
            <strong>Logistics Made Simple</strong>
          </h1>
          <hr />
        </div>
        <div class="col-lg-8 mx-auto">
          <p class="text-faded mb-5"><font color="black"><b>Send Across India with More than 50000+ Branches Covering All Major Cities. Send Parcel With Ease.</b></font></p>
          <button class="btn btn-primary btn-xl js-scroll-trigger" onClick={handleShow}s>Track Shippment</button>
        </div>
      </div>
    </div>
  </header>
  <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Track Shippment</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <input id="awb" type="text" placeholder="Enter Awb Number" class="form-control"/>
                <div id="trackResult"></div>
            </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={trackShippment}>
            Search
          </Button>
        </Modal.Footer>
      </Modal>    
    </div>     
  );
}

export default App;
